import { authedApi } from '../../../api/auth';
import ManagementCompanyUserDetailSchema from '../types/ManagementCompanyUserDetailSchema';

export const REJECT_INVITE_TOAST_ERROR_CODES = [
  'PMC_0004', // Invite revoked.
  'PMC_0005', // Invite already used.
];

export const rejectInvite = async (inviteId: string): Promise<ManagementCompanyUserDetailSchema> => {
  const response = await authedApi.post(`/management-company/invites/${inviteId}/reject`);

  return response.data;
};
