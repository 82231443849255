import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalState {
  trueworkReport: TrueworkReportState;
}

interface TrueworkReportState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: any | null;
}

// interface TrueworkReport {
//   id: string
//   created: string
//   permissible_purpose: string
// }

const trueworkReportSlice = createSlice({
  name: 'trueworkReportCosigner',
  initialState: { report: null },
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTrueworkReport(state, action: PayloadAction<any | null>) {
      state.report = action.payload;
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectTrueworkReport = (state: GlobalState): any | null => {
  return state.trueworkReport.report;
};

export const { setTrueworkReport } = trueworkReportSlice.actions;
export default trueworkReportSlice.reducer;
