export enum Environment {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'PROD',
}

export const getEnvironment = (): Environment => {
  // NOTE: these need to match the env vars in CF
  if (import.meta.env.VITE_ENV === 'prod') {
    return Environment.PROD;
  } else if (import.meta.env.VITE_ENV === 'qa') {
    return Environment.QA;
  }

  return Environment.DEV;
};

type EnvConfig = {
  PERSONA_ENVIRONMENT: 'sandbox' | 'production';
  ARGYLE_IS_SANDBOX: boolean;
  ARGYLE_LINK_KEY: string;
  EXTERNAL_API_ENDPOINT: string | null;
  SHOW_QUERY_DEVTOOLS: boolean;
  INCODE_CLIENT_ID: string;
  INCODE_API_URL: string;
  STRIPE_CLIENT_SECRET: string;
  INTERCOM_WORKSPACE_ID: string;
};

const PROD_CONFIG: EnvConfig = {
  PERSONA_ENVIRONMENT: 'production',
  ARGYLE_IS_SANDBOX: false,
  ARGYLE_LINK_KEY: '0186af08-9154-a1d0-ef1a-a35f6b23bba9',
  EXTERNAL_API_ENDPOINT: 'https://api.getcobblestone.com',
  SHOW_QUERY_DEVTOOLS: false,
  INCODE_CLIENT_ID: 'cobblestoneprod340',
  INCODE_API_URL: 'https://saas-api.incodesmile.com/0/',
  STRIPE_CLIENT_SECRET:
    'pk_live_51MTplSENFyw7pY9VUHWHPK6MlOmsPzsgHH02Ag73Jc2fUKzndZ1kDsx0FUkd3LWnGh3lPgYSo2KWvQoGNbVnI3DB004i6xKE6W',
  INTERCOM_WORKSPACE_ID: 'mj7drru5',
};

const QA_CONFIG: EnvConfig = {
  PERSONA_ENVIRONMENT: 'production',
  ARGYLE_IS_SANDBOX: true,
  ARGYLE_LINK_KEY: '0186af08-9162-96d2-08bc-fbb75b747e2c',
  EXTERNAL_API_ENDPOINT: 'https://qa-api.getcobblestone.com/',
  SHOW_QUERY_DEVTOOLS: false,
  INCODE_CLIENT_ID: 'cobblestoneprod340',
  INCODE_API_URL: 'https://saas-api.incodesmile.com/0/',
  STRIPE_CLIENT_SECRET:
    'pk_test_51MTplSENFyw7pY9VNKVoi5I9nq57XodyR2Ibd85Z1aZYzYs1t80Cwixstu63QHxSkiWfHIjhpscF20ZhiBBNAvCx000twdgRnz',
  INTERCOM_WORKSPACE_ID: 'j1ed8wmk',
};

const DEV_CONFIG: EnvConfig = {
  PERSONA_ENVIRONMENT: 'sandbox',
  ARGYLE_IS_SANDBOX: true,
  ARGYLE_LINK_KEY: '0186af08-9162-96d2-08bc-fbb75b747e2c',
  EXTERNAL_API_ENDPOINT: import.meta.env.VITE_EXTERNAL_API_ENDPOINT || null,
  SHOW_QUERY_DEVTOOLS: import.meta.env.VITE_SHOW_QUERY_DEVTOOLS === 'false' ? false : true,
  INCODE_CLIENT_ID: 'cobblestone825',
  INCODE_API_URL: 'https://demo-api.incodesmile.com/0/',
  STRIPE_CLIENT_SECRET:
    'pk_test_51MTplSENFyw7pY9VNKVoi5I9nq57XodyR2Ibd85Z1aZYzYs1t80Cwixstu63QHxSkiWfHIjhpscF20ZhiBBNAvCx000twdgRnz',
  INTERCOM_WORKSPACE_ID: 'j1ed8wmk',
};

const getConfigForEnvironment = (env: Environment): EnvConfig => {
  if (env === Environment.PROD) {
    return PROD_CONFIG;
  } else if (env === Environment.QA) {
    return QA_CONFIG;
  } else if (env === Environment.DEV) {
    return DEV_CONFIG;
  }

  throw Error(`Invalid Environment ${env}`);
};

let cachedConfig: EnvConfig | null = null;
export const getConfig = (): EnvConfig => {
  if (cachedConfig !== null) {
    return cachedConfig;
  }
  cachedConfig = getConfigForEnvironment(getEnvironment());

  return cachedConfig;
};
