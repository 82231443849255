import { FunctionComponent } from 'react';
import { classNames } from '../../../utils/classNames';

type PikDividerSize = 'xl' | 'l' | 's' | 'xs' | '0';

type Props = {
  size: PikDividerSize;
  visible?: boolean;
};

const PikDivider: FunctionComponent<Props> = ({ size, visible = false }) => {
  let sizeClassName = '';
  switch (size) {
    case 'xl':
      sizeClassName = 'h-[32px]';
      break;
    case 'l':
      sizeClassName = 'h-[24px]';
      break;
    case 's':
      sizeClassName = 'h-[16px]';
      break;
    case 'xs':
      sizeClassName = 'h-[8px]';
      break;
    case '0':
      sizeClassName = 'h-[0px]';
      break;
  }

  let visibleClassName;
  if (visible) {
    visibleClassName = 'border-t border-pik-neutral-100-o75';
  } else {
    visibleClassName = 'border-none';
  }

  const ariaHidden = visible ? undefined : true;

  return (
    <hr aria-hidden={ariaHidden} className={classNames('w-full', sizeClassName, visibleClassName)} />
  );
};

export default PikDivider;
