import { FunctionComponent } from 'react';
import MarkDark from '../../../assets/pik-logos/mark_dark.svg';
import MarkLight from '../../../assets/pik-logos/mark_light.svg';
import WorkmarkDark from '../../../assets/pik-logos/wordmark_dark.svg';
import WorkmarkLight from '../../../assets/pik-logos/wordmark_light.svg';
import { classNames } from '../../../utils/classNames';

type PikLogoType = 'mark' | 'wordmark';

type PikLogoVariant = 'light' | 'dark';

type Props = {
  type: PikLogoType;
  variant: PikLogoVariant;
};

const PikLogo: FunctionComponent<Props> = ({ type, variant }) => {
  if (type === 'mark') {
    if (variant === 'light') {
      return <img src={MarkLight} alt="Cobblestone's logo" />;
    } else if (variant === 'dark') {
      return <img src={MarkDark} alt="Cobblestone's logo" />;
    }
  } else if (type === 'wordmark') {
    const wordmarkClassNames = 'h-[24px]';

    if (variant === 'light') {
      return (
        <img className={classNames(wordmarkClassNames)} src={WorkmarkLight} alt="Cobblestone's logo" />
      );
    } else if (variant === 'dark') {
      return (
        <img className={classNames(wordmarkClassNames)} src={WorkmarkDark} alt="Cobblestone's logo" />
      );
    }
  }

  throw new Error(`Unexpected PikLogo Props`);
};

export default PikLogo;
