import { authedApi } from '../../../api/auth';
import CobblestonePolicyAcceptanceSchema from '../../../features/legal/types/CobblestonePolicyAcceptanceSchema';
import ManagementCompanyUserDetailSchema from '../types/ManagementCompanyUserDetailSchema';

export const ACCEPT_INVITE_TOAST_ERROR_CODES = [
  'PMC_0004', // Invite revoked.
  'PMC_0005', // Invite already used.
];

type AcceptInviteArgs = {
  inviteId: string;
  // null if management company's prefers authn scheme that is not password (i.e. magic link, SSO)
  password: string | null;
  cobblestonePolicyAcceptances: CobblestonePolicyAcceptanceSchema[];
};

export const acceptInvite = async ({
  inviteId,
  password,
  cobblestonePolicyAcceptances,
}: AcceptInviteArgs): Promise<ManagementCompanyUserDetailSchema> => {
  const response = await authedApi.post(`/management-company/invites/${inviteId}/accept`, {
    password: password,
    cobblestone_policy_acceptances: cobblestonePolicyAcceptances,
  });
  return response.data;
};
