import { FunctionComponent } from 'react';

const PikIconReplace: FunctionComponent = () => {
  return (
    <svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 6.5C6 6.5 8.25 4.25 12 4.25C17.25 4.25 20.25 9.5 20.25 9.5M20.25 9.5H15.75M20.25 9.5V5M18 18.5C18 18.5 15.75 20.75 12 20.75C6.75 20.75 3.75 15.5 3.75 15.5M3.75 15.5H8.25M3.75 15.5V20'
        stroke='currentcolor'
        strokeWidth='1.53125'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PikIconReplace;
