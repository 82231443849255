import { FunctionComponent, ReactNode, useState } from 'react';
import PikCardButtonData, { PikCardButtonDataWithDestructive } from './types/PikCardButtonData';
import PikButton from '../PikButton/PikButton';
import { classNames } from '../../../utils/classNames';
import { useLocation } from 'react-router-dom';

type Props = {
  children: ReactNode;
  primaryButtonData?: PikCardButtonDataWithDestructive;
  secondaryButtonData?: PikCardButtonData;
  sideButtonData?: PikCardButtonData;
  fullHeight?: boolean;
  id?: string;
};

const PikCard: FunctionComponent<Props> = ({
  children,
  primaryButtonData,
  secondaryButtonData,
  sideButtonData,
  fullHeight = false,
  id,
}) => {
  const heightClassName = fullHeight ? 'h-full' : '';
  const location = useLocation();
  const [scrolledIntoView, setScrolledIntoView] = useState(false);
  const [anchorClassNames, setAnchorClassNames] = useState('');

  return (
    <div
      id={id}
      ref={(elt) => {
        const hash = location.hash;

        if (!scrolledIntoView && hash === `#${id}` && elt !== null) {
          elt.scrollIntoView({ behavior: 'instant' });
          setAnchorClassNames('anchor-box-shadow');
          setScrolledIntoView(true);

          setTimeout(() => {
            setAnchorClassNames('transition-shadow ease-out duration-[1500ms]');
          }, 10);
        }
      }}
      className={classNames(
        'w-full bg-pik-white rounded-[4px] border border-pik-neutral-100-o75',
        heightClassName,
        anchorClassNames
      )}
    >
      <div className='w-full p-[18px_24px_24px_24px] lg:p-[24px_32px_32px_32px] flex flex-col items-start'>
        {children}
      </div>
      {(primaryButtonData !== undefined ||
        secondaryButtonData !== undefined ||
        sideButtonData !== undefined) && (
        <div className='w-full lg:h-[72px] px-[16px] max-lg:pt-[16px] max-lg:pb-[6px] flex flex-row items-center justify-between max-lg:flex-col-reverse max-lg:justify-end max-lg:space-y-[10px] max-lg:space-y-reverse border-t border-pik-neutral-100-o75'>
          <div className='max-lg:w-full'>
            {sideButtonData !== undefined && (
              <>
                <div className='hidden lg:block'>
                  <PikButton
                    variant='text'
                    size={'medium'}
                    onClick={sideButtonData.actionFn}
                    disabled={sideButtonData.actionDisabled}
                    loading={sideButtonData.actionLoading}
                    disabledTooltip={sideButtonData.disabledTooltipContent}
                    missingFields={sideButtonData.missingFields}
                    fullWidth={false}
                  >
                    {sideButtonData.actionLabel}
                  </PikButton>
                </div>
                <div className='w-full lg:hidden mb-[8px]'>
                  <PikButton
                    variant='text'
                    size={'large'}
                    onClick={sideButtonData.actionFn}
                    disabled={sideButtonData.actionDisabled}
                    loading={sideButtonData.actionLoading}
                    disabledTooltip={sideButtonData.disabledTooltipContent}
                    missingFields={sideButtonData.missingFields}
                    fullWidth={true}
                  >
                    {sideButtonData.actionLabel}
                  </PikButton>
                </div>
              </>
            )}
          </div>
          <div className='max-lg:w-full flex flex-row items-center lg:space-x-[10px] max-lg:flex-col-reverse max-lg:justify-end max-lg:space-y-[10px] max-lg:space-y-reverse'>
            {secondaryButtonData !== undefined && (
              <>
                <div className='hidden lg:block'>
                  <PikButton
                    variant='white'
                    size={'medium'}
                    onClick={secondaryButtonData.actionFn}
                    disabled={secondaryButtonData.actionDisabled}
                    loading={secondaryButtonData.actionLoading}
                    disabledTooltip={secondaryButtonData.disabledTooltipContent}
                    missingFields={secondaryButtonData.missingFields}
                    fullWidth={false}
                  >
                    {secondaryButtonData.actionLabel}
                  </PikButton>
                </div>
                <div className='w-full lg:hidden'>
                  <PikButton
                    variant='white'
                    size={'large'}
                    onClick={secondaryButtonData.actionFn}
                    disabled={secondaryButtonData.actionDisabled}
                    loading={secondaryButtonData.actionLoading}
                    disabledTooltip={secondaryButtonData.disabledTooltipContent}
                    missingFields={secondaryButtonData.missingFields}
                    fullWidth={true}
                  >
                    {secondaryButtonData.actionLabel}
                  </PikButton>
                </div>
              </>
            )}
            {primaryButtonData !== undefined && (
              <>
                <div className='hidden lg:block'>
                  <PikButton
                    variant={primaryButtonData.destructive === true ? 'orange' : 'purple'}
                    size={'medium'}
                    onClick={primaryButtonData.actionFn}
                    disabled={primaryButtonData.actionDisabled}
                    loading={primaryButtonData.actionLoading}
                    disabledTooltip={primaryButtonData.disabledTooltipContent}
                    missingFields={primaryButtonData.missingFields}
                    fullWidth={false}
                  >
                    {primaryButtonData.actionLabel}
                  </PikButton>
                </div>
                <div className='w-full lg:hidden'>
                  <PikButton
                    variant={primaryButtonData.destructive === true ? 'orange' : 'purple'}
                    size={'large'}
                    onClick={primaryButtonData.actionFn}
                    disabled={primaryButtonData.actionDisabled}
                    loading={primaryButtonData.actionLoading}
                    disabledTooltip={primaryButtonData.disabledTooltipContent}
                    missingFields={primaryButtonData.missingFields}
                    fullWidth={true}
                  >
                    {primaryButtonData.actionLabel}
                  </PikButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PikCard;
