import { ReactElement, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { classNames } from '../../../utils/classNames';
import { createPortal } from 'react-dom';
import { useInterval } from '../../../utils/hooks';
import PikTooltipDirection from './types/PikTooltipDirection';
import getTextStylesForSize from '../PikText/utils/getTextStylesForSize';
import PikTooltipPointer from './PikTooltipPointer';

type Props<T extends HTMLElement> = {
  content: string;
  children: ReactNode | ((ref: RefObject<T>) => ReactElement | null);
  direction: PikTooltipDirection;
  mobileDirection?: PikTooltipDirection;
  fullWidth?: boolean;
  fullHeight?: boolean;
  hoverStateOverride?: boolean;
  hidden?: boolean;
};

const PikTooltip = <T extends HTMLElement>({
  content,
  children,
  direction,
  mobileDirection,
  fullWidth,
  fullHeight,
  hoverStateOverride,
  hidden = false,
}: Props<T>): ReactElement | null => {
  const isAbsoluteTooltip = typeof children === 'function';

  const childRef = useRef<T | HTMLDivElement>(null);
  const [hoveringChild, setHoveringChild] = useState<boolean>(
    hoverStateOverride === undefined ? false : hoverStateOverride
  );
  const [shouldScale, setShouldScale] = useState<boolean>(false);
  const [boundingBox, setBoundingBox] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (hoverStateOverride !== undefined) {
      if (hoverStateOverride) {
        setHoveringChild(true);
      }
      if (!hoverStateOverride) {
        setHoveringChild(false);
        setShouldScale(false);
      }
    }
  }, [hoverStateOverride, setHoveringChild]);

  useEffect(() => {
    if (childRef.current !== null) {
      const onMouseOver = () => {
        if (hoverStateOverride === undefined) {
          setHoveringChild(true);
        }
      };
      const onMouseOut = () => {
        if (hoverStateOverride === undefined) {
          setHoveringChild(false);
          setShouldScale(false);
        }
      };

      childRef.current.addEventListener('mouseover', onMouseOver);
      childRef.current.addEventListener('mouseout', onMouseOut);

      return () => {
        if (childRef.current !== null) {
          childRef.current.removeEventListener('mouseover', onMouseOver);
          childRef.current.removeEventListener('mouseout', onMouseOut);
        }
      };
    }
  }, [childRef.current]);

  useEffect(() => {
    if (!shouldScale && hoveringChild) {
      const timeOut = setTimeout(() => {
        setShouldScale(true);
      }, 100);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [shouldScale, setShouldScale, hoveringChild, setHoveringChild]);

  const updateBoundingRect = useMemo(
    () => () => {
      if (childRef.current !== null) {
        const boundingRect = childRef.current.getBoundingClientRect();

        setBoundingBox(boundingRect);
      }
    },
    [childRef.current, setBoundingBox]
  );

  useInterval(updateBoundingRect, 10);

  const onMobile = window.innerWidth < 640;

  let effectiveDirection = direction;
  if (onMobile && mobileDirection !== undefined) {
    effectiveDirection = mobileDirection;
  }

  // We use group-[&:hover]: instead of group-hover: because by we have opted out of
  // IOS's default behaviour of simulating hover wherever the user has last touched the screen.
  // We actually want this for tooltips, otherwise we'd never be able to see them on mobile.
  const tooltipAnimationClassNames = `${
    isAbsoluteTooltip ? 'z-[100001]' : 'z-[1000] scale-0 group-[&:hover]:scale-100'
  } ${isAbsoluteTooltip && (shouldScale ? 'scale-100' : 'scale-0')} transition-transform ${
    isAbsoluteTooltip ? 'duration-[50ms]' : 'duration-[150ms]'
  } gpu-transform`;
  const tooltipBaseClassNames = 'absolute w-max max-w-[300px] p-[12px] rounded-[2px] bg-pik-slate-II';

  let tooltipDirectionClassNames = '';
  let tooltipPointerDirection: 'up' | 'down' = 'down';
  let tooltipPointerPlacementClassNames = '';
  let tooltipLeft: number | undefined;
  let tooltipRight: number | undefined;
  let tooltipBottom: number | undefined;
  let tooltipTop: number | undefined;
  if (boundingBox !== null) {
    if (effectiveDirection === 'top-left') {
      tooltipBottom = boundingBox.height;
      tooltipRight = boundingBox.width * 0.5 - 20;

      tooltipDirectionClassNames = 'mb-[7px] pik-tooltip-bottom-left-clip-path';
      tooltipPointerPlacementClassNames = 'top-[calc(100%_-_3px)] right-[12px]';
    } else if (effectiveDirection === 'top-center') {
      tooltipBottom = boundingBox.height;
      tooltipLeft = boundingBox.width * 0.5;

      tooltipDirectionClassNames = 'translate-x-[-50%] mb-[7px]';
      tooltipPointerPlacementClassNames = 'top-[calc(100%_-_3px)] right-[calc(50%_-_8px)]';
    } else if (effectiveDirection === 'top-right') {
      tooltipBottom = boundingBox.height;
      tooltipLeft = boundingBox.width * 0.5 - 20;

      tooltipDirectionClassNames = 'mb-[7px] pik-tooltip-bottom-right-clip-path';
      tooltipPointerPlacementClassNames = 'top-[calc(100%_-_3px)] left-[12px]';
    } else if (effectiveDirection === 'bottom-left') {
      tooltipTop = boundingBox.height;
      tooltipRight = boundingBox.width * 0.5 - 20;

      tooltipDirectionClassNames = 'mt-[7px] pik-tooltip-bottom-left-clip-path';
      tooltipPointerPlacementClassNames = 'bottom-[calc(100%_-_3px)] right-[12px]';
      tooltipPointerDirection = 'up';
    } else if (effectiveDirection === 'bottom-center') {
      tooltipTop = boundingBox.height;
      tooltipLeft = boundingBox.width * 0.5;

      tooltipDirectionClassNames = 'translate-x-[-50%] mt-[7px]';
      tooltipPointerPlacementClassNames = 'bottom-[calc(100%_-_3px)] right-[calc(50%_-_8px)]';
      tooltipPointerDirection = 'up';
    } else if (effectiveDirection === 'bottom-right') {
      tooltipTop = boundingBox.height;
      tooltipLeft = boundingBox.width * 0.5 - 20;

      tooltipDirectionClassNames = 'mt-[7px] pik-tooltip-bottom-right-clip-path';
      tooltipPointerPlacementClassNames = 'bottom-[calc(100%_-_3px)] left-[12px]';
      tooltipPointerDirection = 'up';
    }

    const tooltipBaseLeft = isAbsoluteTooltip ? boundingBox.left : 0;
    const tooltipBaseRight = isAbsoluteTooltip ? document.body.offsetWidth - boundingBox.right : 0;
    const tooltipBaseBottom = isAbsoluteTooltip ? document.body.offsetHeight - boundingBox.bottom : 0;
    const tooltipBaseTop = isAbsoluteTooltip ? boundingBox.top : 0;

    tooltipLeft = tooltipLeft !== undefined ? tooltipLeft + tooltipBaseLeft : undefined;
    tooltipRight = tooltipRight !== undefined ? tooltipRight + tooltipBaseRight : undefined;
    tooltipTop = tooltipTop !== undefined ? tooltipTop + tooltipBaseTop : undefined;
    tooltipBottom = tooltipBottom !== undefined ? tooltipBottom + tooltipBaseBottom : undefined;
  }

  const toolTipClassNames = classNames(
    tooltipBaseClassNames,
    tooltipAnimationClassNames,
    tooltipDirectionClassNames
  );

  const tooltipContentClassNames = `${getTextStylesForSize('12')} text-pik-white whitespace-pre-wrap font-normal`;

  const toolTipElement = hidden ? null : (
    <div
      className={toolTipClassNames}
      style={{
        left: tooltipLeft && `${tooltipLeft}px`,
        right: tooltipRight && `${tooltipRight}px`,
        bottom: tooltipBottom && `${tooltipBottom}px`,
        top: tooltipTop && `${tooltipTop}px`,
      }}
    >
      <p className={tooltipContentClassNames}>{content}</p>
      {tooltipPointerDirection !== undefined && (
        <div className={classNames('absolute', tooltipPointerPlacementClassNames)}>
          <PikTooltipPointer direction={tooltipPointerDirection} />
        </div>
      )}
    </div>
  );

  if (isAbsoluteTooltip) {
    return (
      <>
        {children(childRef as RefObject<T>)}
        {childRef !== null && (hoveringChild || true) && (
          <>{createPortal(toolTipElement, document.body)}</>
        )}
      </>
    );
  }

  return (
    <div
      ref={childRef as RefObject<HTMLDivElement>}
      className={classNames(
        'group inline-block relative',
        fullWidth === true ? 'w-full' : '',
        fullHeight === true ? 'h-full' : ''
      )}
    >
      {toolTipElement}
      {children}
    </div>
  );
};

export default PikTooltip;
