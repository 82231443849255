enum ErrorDisplayType {
  TOAST = 'TOAST',
  PAGE = 'PAGE',
  SILENT = 'SILENT',
  NOT_FOUND_PAGE = 'NOT_FOUND_PAGE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  REDIRECT = 'REDIRECT',
}

export default ErrorDisplayType;
