import React, { FunctionComponent, RefCallback, useEffect, useRef, useState } from 'react';

export type SupportedLottie = 'loader_dark' | 'loader_light';

const SUPPORTED_LOTTIES_SOURCES = {
  loader_dark: () => import('./_loader_dark.json'),
  loader_light: () => import('./_loader_light.json'),
};

const _Lottie = React.lazy(() => import('lottie-react'));

type Props = {
  animationSource: SupportedLottie;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lottieRef: RefCallback<any>;
  onComplete?: () => void;
  loop?: boolean;
  autoplay?: boolean;
};

const LazyLottie: FunctionComponent<Props> = ({
  animationSource,
  lottieRef,
  onComplete,
  loop,
  autoplay,
}) => {
  const [animationData, setAnimationData] = useState<unknown>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerLottieRef = useRef<any>();

  useEffect(() => {
    SUPPORTED_LOTTIES_SOURCES[animationSource]().then((data) => {
      setAnimationData(() => data.default);
    });
  }, [animationSource]);

  if (animationData === null) {
    return null;
  }

  return (
    <_Lottie
      animationData={animationData}
      lottieRef={innerLottieRef}
      onDOMLoaded={() => {
        if (innerLottieRef.current !== null) {
          lottieRef(innerLottieRef.current);
        }
      }}
      loop={loop}
      autoplay={autoplay}
      onComplete={onComplete}
      data-sentry-block
    />
  );
};

export default LazyLottie;
