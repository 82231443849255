import { configureStore } from '@reduxjs/toolkit';
import addCosigner from './addCosigner';
import trueworkReport from './trueworkReport';

export const rootStore = configureStore({
  reducer: {
    addCosigner,
    trueworkReport,
  },
});
