import { triggerPikToast } from '../base_components/PikToaster/PikToaster';
import { isCobblestoneError } from '../errors/CobblestoneError';
import ErrorDisplayType from '../errors/types/ErrorDisplayType';
import { isRedirectErrorExtraSchema } from '../errors/types/RedirectErrorExtraSchema';
import { unreachable } from './unreachable';

const defaultOnError = (error: unknown) => {
  if (!isCobblestoneError(error)) {
    return;
  }

  const { preferred_display_type, message, extra } = error.response.data;

  switch (preferred_display_type) {
    case ErrorDisplayType.SILENT:
    case ErrorDisplayType.UNAUTHORIZED:
    case ErrorDisplayType.PAGE:
    case ErrorDisplayType.NOT_FOUND_PAGE:
      return;
    case ErrorDisplayType.TOAST:
      triggerPikToast({ variant: 'error', title: message });
      return;
    case ErrorDisplayType.REDIRECT:
      if (isRedirectErrorExtraSchema(extra)) {
        const currentPathname = new URL(window.location.href).pathname;

        for (const { check_path_regex, result_path } of extra.check_paths) {
          const match = new RegExp(check_path_regex).exec(currentPathname);
          if (match !== null) {
            let finalResultPath = result_path;
            if (match.groups !== undefined) {
              for (const [key, value] of Object.entries(match.groups)) {
                finalResultPath = finalResultPath.replace(`\${${key}}`, value);
              }
            }

            navigateFn(finalResultPath);
            return;
          }
        }

        navigateFn(extra.default_url);
        return;
      }
      return;
    default:
      unreachable(preferred_display_type);
  }
};

export default defaultOnError;

const navigateFn = (newPath: string) => {
  window.location.href = new URL(newPath, window.location.href).href;
};
