import RedirectErrorPathMappingSchema from './RedirectErrorPathMappingSchema';

type RedirectErrorExtraSchema = {
  default_url: string;
  check_paths: RedirectErrorPathMappingSchema[];
};

export default RedirectErrorExtraSchema;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRedirectErrorExtraSchema = (obj: any): obj is RedirectErrorExtraSchema => {
  return (
    obj.default_url !== undefined && obj.check_paths !== undefined && Array.isArray(obj.check_paths)
  );
};
