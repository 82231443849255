import { useMutation, useQuery } from '@tanstack/react-query';
import useUrlInviteId from '../hooks/useUrlInviteId';
import getInvite from '../api/getInvite';
import AuthenticationType from '../types/AuthenticationType';
import { FunctionComponent, useState } from 'react';
import { ACCEPT_INVITE_TOAST_ERROR_CODES, acceptInvite } from '../api/acceptInvite';
import { isCobblestoneErrorWithCodes } from '../../../errors/CobblestoneError';
import { triggerPikToast } from '../../../base_components/PikToaster/PikToaster';
import { Navigate } from 'react-router-dom';
import PikLoadingScreen from '../../../base_components/pik/PikSpinner/PikLoadingScreen';
import ImagePaneContainer from '../../../features/application/components/ImagePaneContainer';
import PikLogo from '../../../base_components/pik/PikLogo/PikLogo';
import PikDivider from '../../../base_components/pik/PikDivider/PikDivider';
import PikHeading from '../../../base_components/pik/PikText/PikHeading';
import PikText from '../../../base_components/pik/PikText/PikText';
import { useUserContext } from '../../../providers/UserContext/UserProvider';
import useAdminUserContext from '../../../providers/UserContext/useAdminUserContext';
import SetPassword from './SetPassword';
import CobblestonePolicyAcceptanceSchema from '../../../features/legal/types/CobblestonePolicyAcceptanceSchema';
import PikPageTitle from '../../../base_components/pik/PikPageTitle/PikPageTitle';

type InviteSetupsProps = {
  cobblestonePolicyAcceptanceList?: CobblestonePolicyAcceptanceSchema[] | null;
};

const InviteSetupScreen: FunctionComponent<InviteSetupsProps> = ({
  cobblestonePolicyAcceptanceList = null,
}) => {
  const inviteId = useUrlInviteId();
  const { refetch: refetchUserData } = useUserContext();
  const { data: adminData, refetch: refetchAdminData } = useAdminUserContext();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const reasons = ['must be at least 8 characters long', 'must contain 1 symbol or number'];

  const { status: inviteStatus, data: inviteData } = useQuery(
    ['get-invite', inviteId],
    () => {
      return getInvite(inviteId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (cobblestonePolicyAcceptanceList === null) {
    // should return the user to the original invite screen so the user can accept the terms and conditions
    const navigateLink = `/admin-invite/${inviteId}`;
    return <Navigate to={navigateLink} />;
  }

  const acceptInviteMutation = useMutation(acceptInvite, {
    onError: (error: unknown) => {
      if (!isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES)) {
        throw error;
      }
      triggerPikToast({
        variant: 'error',
        title: 'There was an error',
        body: error.response.data.message,
      });
    },
    useErrorBoundary: (error: unknown) => {
      return !isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES);
    },
  });

  if (inviteStatus !== 'success' || inviteData === undefined) {
    return <PikLoadingScreen />;
  }

  if (inviteData.authentication_type !== AuthenticationType.PASSWORD) {
    throw new Error('This invite does not require a password.');
  }

  if (adminData.management_company.pending_invite === null) {
    return <Navigate to='/admin' />;
  }

  const onSubmit = () => {
    acceptInviteMutation.mutate(
      {
        inviteId: inviteData.id,
        password: newPassword,
        cobblestonePolicyAcceptances: cobblestonePolicyAcceptanceList,
      },
      {
        onSuccess: () => {
          triggerPikToast({
            variant: 'success',
            title: 'Password set successfully',
          });
          refetchUserData();
          refetchAdminData();
        },
      }
    );
  };

  return (
    <PikPageTitle title='Setup your account'>
      <ImagePaneContainer>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          <div className='w-full max-w-[360px] flex flex-col items-center justify-start text-center'>
            <PikLogo type='mark' variant='dark' />
            <PikDivider size='l' />
            <PikHeading size='2'>Setup your account</PikHeading>
            <PikDivider size='xs' />
            <PikText size='s'>Your password must meet the following requirements:</PikText>
            <div className={`w-3/4`}>
              <ul className='text-left pik-ul'>
                {reasons.map((reason, idx) => {
                  return (
                    <li key={idx}>
                      <PikText size='s' color='neutral-500'>
                        {reason}
                      </PikText>
                    </li>
                  );
                })}
              </ul>
            </div>
            <PikDivider size='xl' />
            <PikDivider size='xs' />
            <SetPassword
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              confirmNewPassword={confirmNewPassword}
              setConfirmNewPassword={setConfirmNewPassword}
              onClick={onSubmit}
              isLoading={acceptInviteMutation.isLoading}
            />
          </div>
        </div>
      </ImagePaneContainer>
    </PikPageTitle>
  );
};

export default InviteSetupScreen;
