import { FunctionComponent, useEffect, useId, useState } from 'react';
import PikDivider from '../../../../base_components/pik/PikDivider/PikDivider';
import PikLink from '../../../../base_components/pik/PikText/PikLink';
import CobblestonePolicyAcceptanceSchema from '../../../../features/legal/types/CobblestonePolicyAcceptanceSchema';
import CobblestonePolicyType from '../../../../features/legal/types/CobblestonePolicyType';
import PikCheckbox from '../../../../base_components/pik/PikCheckbox/PikCheckbox';

type Props = {
  setAllTermsAccepted: (value: boolean) => void;
  setCobblestonePolicyAcceptanceList: (value: CobblestonePolicyAcceptanceSchema[]) => void;
  isAdmin: boolean;
  setDisabledHoverText: (value: string | undefined) => void;
  forceDisplayToSAsChecked?: boolean;
};

const AcceptTerms: FunctionComponent<Props> = ({
  setAllTermsAccepted,
  setCobblestonePolicyAcceptanceList,
  isAdmin,
  setDisabledHoverText,
  forceDisplayToSAsChecked = false,
}) => {
  const [termsConditionsAccepted, setTermsConditionsAccepted] = useState(
    false || forceDisplayToSAsChecked
  );
  const [acknowledgeEsignDisclosure, setAcknowledgeEsignDisclosure] = useState(
    false || forceDisplayToSAsChecked
  );

  const termsAndConditionsAgreement = (
    <>
      {`I agree to the `}
      <PikLink size='xs' href='https://getcobblestone.com/terms' inline={true} openIn={'new-tab'}>
        terms and conditions
      </PikLink>
      {` and the `}
      <PikLink
        size='xs'
        href='https://getcobblestone.com/privacy-policy'
        inline={true}
        openIn={'new-tab'}
      >
        privacy policy
      </PikLink>
      {'.'}
    </>
  );
  const esignDisclosure = (
    <>
      {'I acknowledge receipt of the '}
      <PikLink size='xs' href='https://getcobblestone.com/e-sign' inline={true} openIn={'new-tab'}>
        E-SIGN disclosure
      </PikLink>
      {'.'}
    </>
  );

  const acceptanceList = [
    {
      is_accepted: termsConditionsAccepted,
      policy_type: CobblestonePolicyType.TERMS_AND_CONDITIONS,
    },
    {
      is_accepted: termsConditionsAccepted,
      policy_type: CobblestonePolicyType.PRIVACY_POLICY,
    },
  ];

  if (isAdmin === false) {
    acceptanceList.push({
      is_accepted: acknowledgeEsignDisclosure,
      policy_type: CobblestonePolicyType.ESIGN_DISCLOSURE,
    });
  }

  let allTermsAccepted = false;
  if (isAdmin === true) {
    allTermsAccepted = termsConditionsAccepted;
  } else {
    allTermsAccepted = termsConditionsAccepted && acknowledgeEsignDisclosure;
  }

  useEffect(() => {
    setCobblestonePolicyAcceptanceList(acceptanceList);
    setAllTermsAccepted(allTermsAccepted);

    const parts = [];
    if (!termsConditionsAccepted) {
      parts.push('terms and conditions');
    }
    if (!acknowledgeEsignDisclosure && !isAdmin) {
      parts.push('E-SIGN disclosure');
    }
    const hoverText = parts.length > 0 ? 'Please accept the ' + parts.join(' and ') + '.' : undefined;
    setDisabledHoverText(hoverText);
  }, [termsConditionsAccepted, acknowledgeEsignDisclosure]);

  const id = useId();
  const id2 = useId();

  return (
    <div>
      <label className={`flex flex-row space-x-[8px] cursor-pointer`} htmlFor={id}>
        <PikCheckbox
          idForLabel={id}
          checked={termsConditionsAccepted}
          onClick={(checked) => {
            setTermsConditionsAccepted(checked);
          }}
          disabled={false}
        />
        <div className='text-left font-inter text-pik-neutral-500 text-[12px] leading-[140%] font-normal'>
          {termsAndConditionsAgreement}
        </div>
      </label>
      {isAdmin === false && (
        <>
          <PikDivider size='xs' />
          <label className={`flex flex-row space-x-[8px] cursor-pointer`} htmlFor={id2}>
            <PikCheckbox
              idForLabel={id2}
              checked={acknowledgeEsignDisclosure}
              onClick={(checked) => {
                setAcknowledgeEsignDisclosure(checked);
              }}
              disabled={false}
            />
            <div className='text-left font-inter text-pik-neutral-500 text-[12px] leading-[140%] font-normal'>
              {esignDisclosure}
            </div>
          </label>
        </>
      )}
    </div>
  );
};

export default AcceptTerms;
