import { isCobblestoneError } from '../errors/CobblestoneError';
import ErrorDisplayType from '../errors/types/ErrorDisplayType';
import { unreachable } from './unreachable';

const defaultShouldUseErrorBoundary = (error: unknown): boolean => {
  if (!isCobblestoneError(error)) {
    return true;
  }

  const { preferred_display_type } = error.response.data;

  switch (preferred_display_type) {
    case ErrorDisplayType.SILENT:
    case ErrorDisplayType.TOAST:
      return false;
    case ErrorDisplayType.UNAUTHORIZED:
    case ErrorDisplayType.PAGE:
    case ErrorDisplayType.NOT_FOUND_PAGE:
    case ErrorDisplayType.REDIRECT:
      return true;
    default:
      return unreachable(preferred_display_type);
  }
};

export default defaultShouldUseErrorBoundary;
