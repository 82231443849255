import { FunctionComponent } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../providers/UserContext/UserProvider';
import PikLoadingScreen from '../../../base_components/pik/PikSpinner/PikLoadingScreen';
import PikCard from '../../../base_components/pik/PikCard/PikCard';
import PikCardTitle from '../../../base_components/pik/PikCard/PikCardTitle';
import PikButton from '../../../base_components/pik/PikButton/PikButton';
import PikPage from '../../../base_components/pik/PikPage/PikPage';
import PikDivider from '../../../base_components/pik/PikDivider/PikDivider';
import NoResourcesFoundError from '../../../errors/NoResourcesFoundError';

const ExperienceSelect: FunctionComponent = () => {
  const { data: userProfileData } = useUserContext();
  const location = useLocation();

  if (userProfileData === undefined) {
    return <PikLoadingScreen />;
  }

  if (
    userProfileData.has_admin_view &&
    !userProfileData.has_application_view &&
    userProfileData.management_company?.pending_invite !== null
  ) {
    return <Navigate to={`/admin-invite/${userProfileData.management_company.pending_invite.id}`} />;
  } else if (userProfileData.has_admin_view && !userProfileData.has_application_view) {
    return <Navigate to='/admin' />;
  } else if (userProfileData.has_application_view && !userProfileData.has_admin_view) {
    return <Navigate to={`/applications/?prior_pathname=${location.pathname}`} />;
  } else if (!userProfileData.has_admin_view && !userProfileData.has_application_view) {
    throw new NoResourcesFoundError();
  }

  // if a user has a pending invite we will automatically direct them to a page to finish setting up their account
  const adminButtonLink =
    userProfileData.management_company !== null &&
    userProfileData.management_company.pending_invite !== null
      ? `/admin-invite/${userProfileData.management_company?.pending_invite.id}`
      : '/admin/';

  return (
    <div className='w-full h-full bg-pik-neutral-50 flex flex-col items-center justify-center'>
      <PikPage contentWidth='small'>
        <PikCard>
          <PikCardTitle title='Would you like to login as an admin or an applicant?' />
          <PikDivider size='s' />
          <PikDivider size='xl' visible />
          <div className='w-full grid grid-cols-1 gap-[12px]'>
            <Link to={adminButtonLink} rel='path'>
              <PikButton variant='purple' size='large' fullWidth>
                Admin
              </PikButton>
            </Link>
            <Link to='/applications/' rel='path'>
              <PikButton variant='white' size='large' fullWidth>
                Applicant
              </PikButton>
            </Link>
          </div>
        </PikCard>
      </PikPage>
    </div>
  );
};

export default ExperienceSelect;
