const getStaticImageUrl = ({
  id,
  variant,
}: {
  id: string;
  variant: 'cardbody' | 'chartbottom' | 'decisionroom' | 'login' | 'public' | 'well';
}) => {
  return `https://imagedelivery.net/SYvf6EjWMmwXK-YplQn1pg/${id}/${variant}`;
};

export default getStaticImageUrl;
