import { FunctionComponent, Suspense, lazy } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom';
import ExperienceSelect from './features/login/components/ExperienceSelect';
import ErrorHandlerLayout from './features/error_handling/components/ErrorHandlerLayout';
import RouterErrorElement from './features/error_handling/components/RouterErrorElement';
import UserProvider from './providers/UserContext/UserProvider';
import GuaranteedAuthProvider from './components/GuaranteedAuthProvider';
import PikLoadingScreen from './base_components/pik/PikSpinner/PikLoadingScreen';
import AdminInviteRoutes from './route_groups/AdminInviteRoutes';

const ApplicantRoutes = lazy(() => import('./route_groups/ApplicantRoutes'));
const AdminRoutes = lazy(() => import('./route_groups/AdminRoutes'));
const ComplexRoutes = lazy(() => import('./route_groups/ComplexRoutes'));
const LoginRoutes = lazy(() => import('./route_groups/LoginRoutes'));
const PdfRoutes = lazy(() => import('./route_groups/PdfRoutes'));

const _App: FunctionComponent = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path='/'
        element={
          // Wrap site in generic error handler
          <ErrorHandlerLayout>
            <Outlet />
          </ErrorHandlerLayout>
        }
        errorElement={<RouterErrorElement />}
      >
        <Route index element={<Navigate to='/login' replace />} />
        <Route
          path='login/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <LoginRoutes />
            </Suspense>
          }
        />
        <Route path='logout' lazy={() => import('./features/login/components/LogoutPage')} />
        <Route
          path='auth'
          lazy={() => import('./features/login/components/AuthPageWithErrorBoundary')}
        />
        <Route
          path='reset-password'
          lazy={() => import('./features/login/components/ResetPasswordScreen')}
        />
        <Route
          path='complex/:complexId/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <ComplexRoutes />
            </Suspense>
          }
        />
        <Route
          path='experience'
          element={
            <GuaranteedAuthProvider>
              <UserProvider>
                <Outlet />
              </UserProvider>
            </GuaranteedAuthProvider>
          }
        >
          <Route path='select' element={<ExperienceSelect />} />
        </Route>
        <Route
          path='admin-invite/:inviteId/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <AdminInviteRoutes />
            </Suspense>
          }
        />

        <Route
          path='admin/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <AdminRoutes />
            </Suspense>
          }
        />
        <Route
          path='invitation-declined'
          index
          lazy={() => import('./features/applicantManagement/components/InvitationDeclinedScreen')}
        />
        <Route
          path='applications/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <ApplicantRoutes />
            </Suspense>
          }
        />
        <Route
          path='verify/continue'
          lazy={() => import('./features_common/idv/components/ContinueIDVScreen')}
        />
        <Route
          path='pdf/*'
          element={
            <Suspense fallback={<PikLoadingScreen />}>
              <PdfRoutes />
            </Suspense>
          }
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

const App: FunctionComponent = () => {
  // The loading screen itself lazy loads, so add a fallback for it
  // as well
  return (
    <Suspense fallback={null}>
      <Suspense fallback={<PikLoadingScreen />}>
        <_App />
      </Suspense>
    </Suspense>
  );
};

export default App;
