import { CobblestoneError } from './CobblestoneError';

class NotFoundFromUrlIdError {
  declare error: CobblestoneError;

  constructor(error: CobblestoneError) {
    this.error = error;
  }
}

export default NotFoundFromUrlIdError;
