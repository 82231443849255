import { Fragment, FunctionComponent } from 'react';
import { classNames } from '../../../utils/classNames';
import { Transition } from '@headlessui/react';
import Lottie, { SupportedLottie } from '../../../assets/lotties/Lottie';

type PikLoaderVariant = 'dark' | 'light';

type PikLoaderSize = 'large' | 'medium' | 'small';

type Props = {
  variant?: PikLoaderVariant;
  size: PikLoaderSize;
  fadeIn?: boolean;
};

const PikSpinner: FunctionComponent<Props> = ({ variant = 'dark', size, fadeIn = true }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lottiePlayerRef = (ref: any) => {
    ref.setSpeed(1.75);
  };

  let lottieSource: SupportedLottie;
  switch (variant) {
    case 'dark':
      lottieSource = 'loader_dark';
      break;
    case 'light':
      lottieSource = 'loader_light';
      break;
  }

  let sizeClassNames = '';
  switch (size) {
    case 'large':
      sizeClassNames = 'w-[128px] h-[128px]';
      break;
    case 'medium':
      sizeClassNames = 'w-[48px] h-[48px]';
      break;
    case 'small':
      sizeClassNames = 'w-[20px] h-[20px]';
      break;
  }

  return (
    <Transition
      as={Fragment}
      show
      appear={fadeIn}
      // Trying to find a balance between flashing loading screens and
      // never actually having the spinner appear
      enter='transition-opacity duration-[1000ms] ease-[cubic-bezier(0.99,0.04,0.84,0.59)]'
      enterFrom='opacity-0'
      enterTo='opacity-100'
    >
      <div className={classNames(sizeClassNames)}>
        <Lottie animationSource={lottieSource} lottieRef={lottiePlayerRef} loop autoplay />
      </div>
    </Transition>
  );
};

export default PikSpinner;
