import { FunctionComponent } from 'react';
import { PikIconSource } from './PikIconSources';
import { classNames } from '../../../utils/classNames';
import PikIconSize from './types/PikIconSize';
import PikIconColor from './types/PikIconColor';

type Props = {
  iconSource: PikIconSource;
  size?: PikIconSize;
  color?: PikIconColor;
};

const PikIcon: FunctionComponent<Props> = ({ iconSource, size = 'regular', color = 'dark' }) => {
  let sizeClassNames = '';
  if (size === 'regular') {
    sizeClassNames = 'w-[20px] h-[20px]';
  } else if (size === 'medium') {
    sizeClassNames = 'w-[18px] h-[18px]';
  } else if (size === 'small') {
    sizeClassNames = 'w-[16px] h-[16px]';
  } else if (size === 'large') {
    sizeClassNames = 'w-[24px] h-[24px]';
  } else if (size === 'x-large') {
    sizeClassNames = 'w-[32px] h-[32px]';
  } else if (size === 'xx-large') {
    sizeClassNames = 'w-[40px] h-[40px]';
  }

  let colorClassNames = '';
  if (color === 'light') {
    colorClassNames = 'text-pik-white';
  } else if (color === 'dark') {
    colorClassNames = 'text-pik-slate-I';
  } else if (color === 'inherit') {
    colorClassNames = 'text-inherit';
  } else if (color === 'purple-product') {
    colorClassNames = 'text-pik-purple-product';
  }

  const IconSource = iconSource;

  return (
    <div aria-hidden={true} className={classNames(sizeClassNames, colorClassNames)}>
      <IconSource />
    </div>
  );
};

export default PikIcon;
