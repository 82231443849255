import { FunctionComponent } from 'react';

const PikIconCheck: FunctionComponent = () => {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 12.75L8.86957 18L20 6'
        stroke='currentcolor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PikIconCheck;
