import getUserProfile from '../../api/getUserProfile';
import UserContext from './UserContext';
import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import useBackgroundQueries from '../AuthProvider/useBackgroundQueries';

type UserProviderProps = {
  children: React.ReactNode;
};
const UserProvider = ({ children }: UserProviderProps) => {
  const {
    status: userStatus,
    data: userData,
    refetch: refetchUserData,
  } = useQuery(['get-user'], getUserProfile, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  useBackgroundQueries();

  useEffect(() => {
    if (userData === undefined) {
      return;
    }
    let username =
      userData.first_name !== null && userData.last_name !== null
        ? `${userData.first_name} ${userData.last_name}`
        : undefined;

    if (userData.is_impersonation) {
      if (username !== undefined) {
        username = `Impersonation of ${username} `;
      } else {
        username = `Impersonation of ${userData.email}`;
      }
    }

    Sentry.setUser({
      id: userData.id,
      email: userData.email,
      username,
    });
  }, [userData]);

  const contextValue = {
    status: userStatus,
    data: userData,
    refetch: refetchUserData,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

// Hooked to have a fully typed UserContext
export const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) throw new Error('No UserContext.Provider found when calling useUserContext.');
  return userContext;
};

export default UserProvider;
