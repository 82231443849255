import * as Sentry from '@sentry/browser';
import { FunctionComponent } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import isDynamicImportError from '../../../errors/isDynamicImportError';

const RouterErrorElement: FunctionComponent = () => {
  const routeError = useRouteError();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isDynamicImportError(routeError)) {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
    return null;
  }

  if (isRouteErrorResponse(routeError) && routeError.status === 404) {
    return <ErrorPage errorType='404' />;
  }

  Sentry.captureException(routeError);

  return <ErrorPage errorType='generic' />;
};

export default RouterErrorElement;
