import { FunctionComponent, createContext, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider/AuthProvider';
import DEFAULT_LOG_IN_PAGE from '../features/login/DEFAULT_LOG_IN_PAGE';
import { isCobblestoneError } from '../errors/CobblestoneError';
import { ErrorBoundary } from 'react-error-boundary';
import isDynamicImportError from '../errors/isDynamicImportError';

type GuaranteedAuthProviderProps = {
  children: React.ReactNode;
};

const GuaranteedAuthProvider = ({ children }: GuaranteedAuthProviderProps) => {
  const authContext = useAuthContext();

  if (authContext.identityId == null) {
    return <Navigate to={DEFAULT_LOG_IN_PAGE} />;
  }

  return (
    <GuaranteedAuthContext.Provider value={{ identityId: authContext.identityId }}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>{children}</ErrorBoundary>
    </GuaranteedAuthContext.Provider>
  );
};

export default GuaranteedAuthProvider;

type GuaranteedAuthContextType = {
  identityId: string;
};

const GuaranteedAuthContext = createContext<GuaranteedAuthContextType | undefined>(undefined);

export const useGuaranteedAuthContext = (): GuaranteedAuthContextType => {
  const context = useContext(GuaranteedAuthContext);
  if (context === undefined) {
    throw new Error(
      'useGuaranteedAuthContext must be used within a component that is a child of GuaranteedAuthProvider'
    );
  }
  return context;
};

type ErrorHandlerProps = {
  error: Error;
};

const ErrorHandler: FunctionComponent<ErrorHandlerProps> = ({ error }) => {
  const authContext = useAuthContext();

  if (isDynamicImportError(error)) {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
    return null;
  }

  if (isCobblestoneError(error) && error.response.status === 401) {
    authContext.logout();
    return <Navigate to='/login?refresh=true' />;
  } else {
    throw error;
  }
};
