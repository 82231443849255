import axios, { AxiosError, AxiosHeaders } from 'axios';
import { getApiURL } from '../utils/api';

export const JWT_STORAGE_KEY = 'COBBLESTONE_JWT';
export const IDENTITY_STORAGE_KEY = 'COBBLESTONE_IDENTITY';
export const WEAK_JWT_STORAGE_KEY = 'COBBLESTONE_WEAK_JWT';
const COBBLESTONE_AUTH_HEADER = 'Authentication-Token';

export const authedApi = axios.create({
  baseURL: getApiURL(),
});

authedApi.interceptors.request.use((config) => {
  const headers = (
    config.headers === null || config.headers === undefined ? {} : config.headers
  ) as AxiosHeaders;

  headers.set(COBBLESTONE_AUTH_HEADER, localStorage.getItem(JWT_STORAGE_KEY));
  config.headers = headers;

  return config;
});

authedApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: Error | AxiosError) => {
    return Promise.reject(error);
  }
);
