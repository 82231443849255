import { isAxiosError } from 'axios';
import { isCobblestoneError } from '../errors/CobblestoneError';

const defaultShouldRetry = (failureCount: number, error: unknown) => {
  if (isAxiosError(error) && !error.response?.status) {
    // Axios errors without a status code are network errors
    // we should retry them.
    return failureCount < 2;
  }

  if (!isCobblestoneError(error)) {
    return false;
  }

  const { status } = error.response;
  if (400 <= status && status < 500) {
    return false;
  }

  // Confusingly, this results in 3 total attempts of the query
  return failureCount < 2;
};

export default defaultShouldRetry;
