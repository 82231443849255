import { FunctionComponent } from 'react';
import PikSpinner from './PikSpinner';
import PikText from '../PikText/PikText';
import { Transition } from '@headlessui/react';

type PikLoadingScreenProps = {
  message?: string;
  fadeIn?: boolean;
};

const PikLoadingScreen: FunctionComponent<PikLoadingScreenProps> = ({
  message,
  fadeIn = true,
}: PikLoadingScreenProps) => {
  const className =
    'w-full h-full min-h-[600px] flex flex-col justify-center items-center bg-pik-neutral-50';

  let messageComponent = null;
  if (message !== undefined) {
    messageComponent = (
      <Transition
        className='text-center'
        show
        appear={fadeIn}
        // Trying to find a balance between flashing loading screens and
        // never actually having the information appear
        enter='transition-opacity duration-[1000ms] ease-[cubic-bezier(0.99,0.04,0.84,0.59)]'
        enterFrom='opacity-0'
        enterTo='opacity-100'
      >
        <div className='text-center'>
          <PikText>{message}</PikText>
        </div>
      </Transition>
    );
  }

  return (
    <div className={className}>
      <PikSpinner size='large' fadeIn={fadeIn} />
      {messageComponent}
    </div>
  );
};

export default PikLoadingScreen;
