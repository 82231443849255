import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { rootStore } from '../src/state/root';
import { AuthProvider } from './providers/AuthProvider/AuthProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getConfig } from './utils/env';
import { HelmetProvider } from 'react-helmet-async';
import defaultOnError from './utils/defaultOnError';
import defaultShouldUseErrorBoundary from './utils/defaultShouldUseErrorBoundary';
import defaultShouldRetry from './utils/defaultShouldRetry';

interface AppProviderProps {
  children: JSX.Element;
}

export const AppProvider: React.FunctionComponent<AppProviderProps> = ({
  children,
}: AppProviderProps) => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: defaultOnError,
    }),
    defaultOptions: {
      queries: {
        useErrorBoundary: defaultShouldUseErrorBoundary,
        retry: defaultShouldRetry,
      },
      mutations: {
        useErrorBoundary: defaultShouldUseErrorBoundary,
        retry: defaultShouldRetry,
        onError: defaultOnError,
      },
    },
  });

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ReduxProvider store={rootStore}>{children}</ReduxProvider>
          {getConfig().SHOW_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
