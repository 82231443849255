import { FunctionComponent, ReactNode } from 'react';
import { classNames } from '../../../utils/classNames';
import PikCheckboxDisplay from './PikCheckboxDisplay';
import PikTooltip from '../PikTooltip/PikTooltip';

type BaseProps = {
  checked: boolean;
  onClick: (checked: boolean) => void;
  disabled?: boolean;
  disabledHoverText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

type Props = BaseProps &
  (
    | {
        ariaLabel: string;
        idForLabel?: undefined;
      }
    | {
        ariaLabel?: undefined;
        idForLabel: string;
      }
  );

const PikCheckbox: FunctionComponent<Props> = ({
  ariaLabel,
  idForLabel,
  checked,
  onClick,
  disabled = false,
  disabledHoverText,
  onBlur,
}) => {
  const baseOuterDivClassNames =
    'z-[1] relative group w-[20px] h-[20px] flex shrink-0 justify-center items-center rounded-[2px]';
  const focusOutlineStyles =
    'focus-visible:outline-offset-2 focus-visible:outline focus-visible:outline-[2px] focus-visible:outline-pik-purple-product focus-visible:rounded-[2px]';
  const baseInputClassNames = 'absolute w-full h-full bg-transparent rounded-[2px] border-none';

  let disabledInputClassNames = '';
  if (disabled) {
    disabledInputClassNames = 'cursor-not-allowed';
  } else {
    disabledInputClassNames = 'cursor-pointer';
  }

  const buttonDisplay: ReactNode = <PikCheckboxDisplay checked={checked} disabled={disabled} />;

  return (
    <div className={classNames(baseOuterDivClassNames)}>
      <input
        id={idForLabel}
        type='checkbox'
        aria-label={ariaLabel}
        className={classNames(baseInputClassNames, disabledInputClassNames, focusOutlineStyles)}
        checked={checked}
        onChange={(e) => {
          onClick(e.target.checked);
        }}
        disabled={disabled}
        onBlur={onBlur}
      />
      {!disabled || disabledHoverText === undefined ? (
        buttonDisplay
      ) : (
        <PikTooltip direction='top-right' content={disabledHoverText} fullWidth fullHeight>
          {buttonDisplay}
        </PikTooltip>
      )}
    </div>
  );
};

export default PikCheckbox;
