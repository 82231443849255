import { useEffect, useRef, useState } from 'react';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';

export const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current != null) {
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
};

// Should only be used in paths that definitely have an `applicationId` path parameter.
export const useUrlApplicationId = () => {
  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applicationId: string = params.applicationId!;

  return { applicationId };
};

type UseIntersectionObserverArgs = {
  onVisible?: () => void;
} & IntersectionObserverInit;

export const useIntersectionObserver = ({
  onVisible,
  root = null,
  rootMargin = '0px',
  threshold = 0,
}: UseIntersectionObserverArgs) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const [observedNode, setObservedNode] = useState<HTMLElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    let currentObserver: IntersectionObserver | null = null;
    if (observedNode !== null) {
      observer.current = new IntersectionObserver((entries) => setEntry(entries[0]), {
        root,
        rootMargin,
        threshold,
      });

      currentObserver = observer.current;

      currentObserver.observe(observedNode);
    }

    return () => currentObserver?.disconnect();
  }, [observedNode, root, rootMargin, threshold]);

  const setRef = (element: HTMLElement | null) => {
    if (element !== null) {
      setObservedNode(element);
    }
  };

  useEffect(() => {
    if (entry !== null && entry.isIntersecting && onVisible !== undefined) {
      onVisible();
    }
  }, [entry]);

  return [setRef] as [(element: HTMLElement | null) => void];
};

type UseForceNavigateArgs = {
  onNavigate?: () => void; // Fn that fires just before navigation
};

export const useForceNavigate = ({ onNavigate }: UseForceNavigateArgs) => {
  const navigate = useNavigate();

  const forceNavigate = (to: string, options?: NavigateOptions | undefined) => {
    if (onNavigate !== undefined) {
      onNavigate();
    }

    if (to !== location.pathname) {
      navigate(to, options);
    } else {
      // Navigating to 0 refreshes the page.
      navigate(0);
    }
  };

  return {
    forceNavigate,
  };
};

// Should only be used in paths that definitely have an `floorplanId` path parameter.
export const useUrlFloorplanId = () => {
  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const floorplanId: string = params.floorplanId!;

  return { floorplanId };
};

// Should only be used in paths that definitely have an `unitId` path parameter.
export const useUrlUnitId = () => {
  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const unitId: string = params.unitId!;

  return { unitId };
};
