import { FunctionComponent, ReactNode } from 'react';
import getStaticImageUrl from '../../../utils/getStaticImageUrl';

type Props = {
  children: ReactNode;
};

const ImagePaneContainer: FunctionComponent<Props> = ({ children }) => {
  return (
    <div className='w-full h-full flex flex-row bg-pik-white'>
      <div className='h-full w-full max-w-[50%] p-[24px] flex max-lg:hidden'>
        <img
          className='w-full object-cover pik-login-image-clip-path'
          src={getStaticImageUrl({ id: 'a621ad60-646f-424a-e38b-248570ed8600', variant: 'login' })}
          alt=''
        />
      </div>
      <div className='h-full grow min-w-[375px] flex flex-col items-center px-[16px]'>{children}</div>
    </div>
  );
};

export default ImagePaneContainer;
