import { FunctionComponent } from 'react';
import { classNames } from '../../../utils/classNames';

type PikHeadingSize = '1' | '2' | '3' | '4' | '5';

type Props = {
  size: PikHeadingSize;
  serif?: boolean;
  fullWidth?: boolean; // Make element tag width: 100%;
  children: string;
};

const PikHeading: FunctionComponent<Props> = ({ serif = false, size, fullWidth = false, children }) => {
  let fontClassNames = '';
  if (serif) {
    fontClassNames = 'font-spezia-serif';
  } else {
    fontClassNames = 'font-[500] font-inter';
  }

  let headingSizeClassNames = '';
  if (size === '1') {
    headingSizeClassNames = `text-[40px] ${serif ? 'tracking-[-1.6px]' : 'tracking-[-0.8px]'}`;
  } else if (size === '2') {
    headingSizeClassNames = `text-[32px] ${serif ? 'tracking-[-1.28px]' : 'tracking-[-0.48px]'}`;
  } else if (size === '3') {
    headingSizeClassNames = `text-[24px] ${serif ? 'tracking-[-0.96px]' : 'tracking-[-0.36px]'}`;
  } else if (size === '4') {
    headingSizeClassNames = `text-[20px] ${serif ? 'tracking-[-0.8px]' : 'tracking-[-0.24px]'}`;
  } else if (size === '5') {
    headingSizeClassNames = `text-[16px] ${serif ? 'tracking-[-0.64px]' : 'tracking-[-0.18px]'}`;
  }

  const textLayoutClassNames = 'leading-[130%]';

  let fullWidthClassName = '';
  if (fullWidth) {
    fullWidthClassName = 'w-full';
  }

  return (
    <p
      className={classNames(
        'text-pik-slate-I',
        fontClassNames,
        headingSizeClassNames,
        textLayoutClassNames,
        fullWidthClassName
      )}
    >
      {children}
    </p>
  );
};

export default PikHeading;
