import { FunctionComponent } from 'react';
import { classNames } from '../../../utils/classNames';
import PikIcon from '../PikIcon/PikIcon';
import PikIconCheck from '../PikIcon/icons/Check/PikIconCheck';

type Props = {
  checked: boolean;
  disabled?: boolean;
};

const PikCheckboxDisplay: FunctionComponent<Props> = ({ checked, disabled = false }) => {
  const baseInnerBoxClassNames = 'h-[18px] w-[18px] rounded-[1px] pik-tl-br-inner-clip-path';
  let innerBoxBackgroundClassNames = 'bg-pik-white group-hover:bg-pik-neutral-50';

  let checkedOuterBoxClassNames = '';
  let outerBoxBackgroundClassNames = '';
  if (checked) {
    checkedOuterBoxClassNames = 'pik-tl-br-checked-clip-path';
    outerBoxBackgroundClassNames = 'bg-pik-purple-product';
  } else {
    checkedOuterBoxClassNames = 'pik-tl-br-outer-clip-path';
    outerBoxBackgroundClassNames = 'bg-pik-purple-product-light group-hover:bg-pik-slate-I';
  }

  let disabledOuterBoxClassNames = '';
  if (disabled) {
    if (checked) {
      disabledOuterBoxClassNames = 'brightness-75';
    } else {
      outerBoxBackgroundClassNames = 'bg-pik-neutral-100 group-hover:bg-pik-neutral-100';
    }
    innerBoxBackgroundClassNames = 'bg-pik-neutral-50 group-hover:bg-pik-neutral-50';
  }

  return (
    <div
      className={classNames(
        'z-[-1] w-full h-full flex justify-center items-center rounded-[2px]',
        checkedOuterBoxClassNames,
        disabledOuterBoxClassNames,
        outerBoxBackgroundClassNames
      )}
    >
      {checked ? (
        <PikIcon iconSource={PikIconCheck} size='small' color='light' />
      ) : (
        <div className={classNames(baseInnerBoxClassNames, innerBoxBackgroundClassNames)} />
      )}
    </div>
  );
};

export default PikCheckboxDisplay;
