import { useEffect, useState } from 'react';

const useLocalStorage: (
  keyName: string,
  defaultValue: string | null
) => [string | null, (newValue: string | null) => void, string | null | undefined] = (
  keyName: string,
  defaultValue: string | null
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);

      if (value !== null) {
        return value;
      } else {
        if (defaultValue !== null) {
          localStorage.setItem(keyName, defaultValue);
        }
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  // undefined is used to indicate that there is not yet a previous value in this session
  const [prevValue, setPrevValue] = useState<string | null | undefined>(undefined);

  const setValue = (newValue: string | null) => {
    if (newValue === null) {
      localStorage.removeItem(keyName);
    } else {
      localStorage.setItem(keyName, newValue);
    }
    setPrevValue(storedValue);
    setStoredValue(newValue);
  };

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === keyName) {
        setPrevValue(storedValue);
        setStoredValue(event.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [keyName]);

  return [storedValue, setValue, prevValue];
};

export default useLocalStorage;
