import { useMutation, useQuery } from '@tanstack/react-query';
import useUrlInviteId from '../hooks/useUrlInviteId';
import getInvite from '../api/getInvite';
import InviteDetailSchema from '../types/InviteDetailSchema';
import AuthenticationType from '../types/AuthenticationType';
import { ACCEPT_INVITE_TOAST_ERROR_CODES, acceptInvite } from '../api/acceptInvite';
import { isCobblestoneErrorWithCodes } from '../../../errors/CobblestoneError';
import { triggerPikToast } from '../../../base_components/PikToaster/PikToaster';
import { rejectInvite } from '../api/rejectInvite';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../../../providers/UserContext/UserProvider';
import { useAuthContext } from '../../../providers/AuthProvider/AuthProvider';
import ImagePaneContainer from '../../../features/application/components/ImagePaneContainer';
import PikLogo from '../../../base_components/pik/PikLogo/PikLogo';
import PikDivider from '../../../base_components/pik/PikDivider/PikDivider';
import PikHeading from '../../../base_components/pik/PikText/PikHeading';
import PikLoadingScreen from '../../../base_components/pik/PikSpinner/PikLoadingScreen';
import PikButton from '../../../base_components/pik/PikButton/PikButton';
import useAdminUserContext from '../../../providers/UserContext/useAdminUserContext';
import AcceptTerms from '../../../features_common/idv/components/steps/AcceptTerms';
import { useState } from 'react';
import CobblestonePolicyType from '../../../features/legal/types/CobblestonePolicyType';
import InviteSetupScreen from './InviteSetupScreen';
import PikPageTitle from '../../../base_components/pik/PikPageTitle/PikPageTitle';

const InviteScreen = () => {
  const inviteId = useUrlInviteId();
  const { logout } = useAuthContext();
  const { refetch: refetchUserData } = useUserContext();
  const { data: adminData } = useAdminUserContext();
  const [allTermsAccepted, setAllTermsAccepted] = useState(false);
  const [cobblestonePolicyAcceptanceList, setCobblestonePolicyAcceptanceList] = useState([
    {
      is_accepted: false,
      policy_type: CobblestonePolicyType.TERMS_AND_CONDITIONS,
    },
    {
      is_accepted: false,
      policy_type: CobblestonePolicyType.PRIVACY_POLICY,
    },
  ]);
  const [setupScreenOpen, setSetupScreenOpen] = useState(false);
  const [disabledHoverText, setDisabledHoverText] = useState<string | undefined>(undefined);

  const { status: inviteStatus, data: inviteData } = useQuery(
    ['get-invite', inviteId],
    () => {
      return getInvite(inviteId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const acceptInviteMutation = useMutation(acceptInvite, {
    onError: (error: unknown) => {
      if (!isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES)) {
        throw error;
      }
      triggerPikToast({
        variant: 'error',
        title: 'Error accepting invite',
        body: error.response.data.message,
      });
    },
    useErrorBoundary: (error: unknown) => {
      return !isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES);
    },
  });

  const rejectInviteMutation = useMutation(rejectInvite, {
    onError: (error: unknown) => {
      if (!isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES)) {
        throw error;
      }
      triggerPikToast({
        variant: 'error',
        title: 'Error rejecting invite',
        body: error.response.data.message,
      });
    },
    useErrorBoundary: (error: unknown) => {
      return !isCobblestoneErrorWithCodes(error, ACCEPT_INVITE_TOAST_ERROR_CODES);
    },
  });

  if (adminData.management_company.pending_invite === null) {
    return <Navigate to='/admin' />;
  }

  if (inviteStatus !== 'success' || inviteData === undefined) {
    return <PikLoadingScreen />;
  }

  let acceptOnClick;
  const passwordSetupRequired = inviteData.authentication_type === AuthenticationType.PASSWORD;

  if (setupScreenOpen === true) {
    // if a password is required, the user will be put on the password setup page
    return <InviteSetupScreen cobblestonePolicyAcceptanceList={cobblestonePolicyAcceptanceList} />;
  }

  if (passwordSetupRequired) {
    acceptOnClick = () => {
      setSetupScreenOpen(true);
    };
  } else {
    acceptOnClick = () => {
      acceptInviteMutation.mutate(
        { inviteId, password: null, cobblestonePolicyAcceptances: cobblestonePolicyAcceptanceList },
        {
          onSuccess: () => {
            triggerPikToast({
              variant: 'success',
              title: 'Invite accepted successfully.',
            });
            refetchUserData();
          },
        }
      );
    };
  }

  return (
    <PikPageTitle title='Accept your invite'>
      <ImagePaneContainer>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          <div className='w-full max-w-[360px] flex flex-col items-center justify-start text-center'>
            <PikLogo type='mark' variant='dark' />
            <PikDivider size='l' />
            <PikHeading size='2'>{getTitleForInvite(inviteData)}</PikHeading>
            <PikDivider size='xl' />
            <AcceptTerms
              setAllTermsAccepted={setAllTermsAccepted}
              setCobblestonePolicyAcceptanceList={setCobblestonePolicyAcceptanceList}
              isAdmin={true}
              setDisabledHoverText={setDisabledHoverText}
            />
            <PikDivider size='l' />
            <PikButton
              size='large'
              variant='purple'
              onClick={acceptOnClick}
              loading={acceptInviteMutation.isLoading}
              disabled={rejectInviteMutation.isLoading || !allTermsAccepted}
              disabledTooltip={disabledHoverText}
              fullWidth
            >
              Accept
            </PikButton>
            <PikDivider size='xs' />
            <PikButton
              size='medium'
              variant='white'
              onClick={() => {
                rejectInviteMutation.mutate(inviteId, {
                  onSuccess: () => {
                    triggerPikToast({
                      variant: 'success',
                      title: 'Invite rejected',
                    });
                    logout();
                  },
                });
              }}
              loading={rejectInviteMutation.isLoading}
              disabled={acceptInviteMutation.isLoading}
              fullWidth
            >
              Decline
            </PikButton>
          </div>
        </div>
      </ImagePaneContainer>
    </PikPageTitle>
  );
};

const getTitleForInvite = (inviteData: InviteDetailSchema) => {
  return `You've been invited to ${inviteData.management_company.name}`;
};

export default InviteScreen;
