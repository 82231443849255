import { FunctionComponent } from 'react';
import { classNames } from '../../../utils/classNames';
import getTextStylesForSize from './utils/getTextStylesForSize';
import PikTextColor from './types/PikTextColor';
import PikTextWeight from './types/PikTextWeight';
import PikTextSize from './types/PikTextSize';
import getTextStylesForColor from './utils/getTextStylesForColor';
import { unreachable } from '../../../utils/unreachable';

type Props = {
  size?: PikTextSize;
  weight?: PikTextWeight;
  color?: PikTextColor;
  fullWidth?: boolean; // Make p tag width: 100%;
  inline?: boolean; // Render p tag inline
  truncate?: boolean; // Truncate text with ellipsis
  noLineHeight?: boolean; // Remove line height
  italicize?: boolean;
  lineThrough?: boolean;
  children: string;
  sentryMask?: boolean;
};

const PikText: FunctionComponent<Props> = ({
  size = 'm',
  weight = 'regular',
  color = 'slate-I',
  fullWidth = false,
  inline = false,
  truncate = false,
  noLineHeight = false,
  italicize = false,
  lineThrough = false,
  children,
  sentryMask = false,
}) => {
  const colorClassNames = getTextStylesForColor(color);

  const sizeClassNames = (() => {
    switch (size) {
      case 'l':
        return getTextStylesForSize('18');
      case 'm':
        return getTextStylesForSize('16');
      case 's':
        return getTextStylesForSize('14');
      case 'xs':
        return getTextStylesForSize('12');
      default:
        return unreachable(size);
    }
  })();

  const weightClassNames = (() => {
    switch (weight) {
      case 'regular':
        return 'font-normal';
      case 'medium':
        return 'font-medium';
      default:
        return unreachable(weight);
    }
  })();

  const fullWidthClassName = fullWidth ? 'w-full' : '';
  const inlineClassNames = inline ? 'inline' : '';
  const truncateClassName = truncate ? 'truncate' : '';
  const noLineHeightClassName = noLineHeight ? '!leading-[100%]' : '';
  const italicizeClassName = italicize ? 'inter-italic' : '';
  const lineThroughClassName = lineThrough ? 'line-through' : '';

  return (
    <p
      className={classNames(
        'font-inter',
        colorClassNames,
        sizeClassNames,
        weightClassNames,
        fullWidthClassName,
        inlineClassNames,
        truncateClassName,
        noLineHeightClassName,
        italicizeClassName,
        lineThroughClassName
      )}
      {...(sentryMask ? { 'data-sentry-mask': true } : {})}
    >
      {children}
    </p>
  );
};

export default PikText;
