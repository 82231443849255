import { FunctionComponent } from 'react';

const PikIconCloseSquareFilled: FunctionComponent = () => {
  return (
    <svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.5 3.5H4.5C4.10218 3.5 3.72064 3.65804 3.43934 3.93934C3.15804 4.22064 3 4.60218 3 5V20C3 20.3978 3.15804 20.7794 3.43934 21.0607C3.72064 21.342 4.10218 21.5 4.5 21.5H19.5C19.8978 21.5 20.2794 21.342 20.5607 21.0607C20.842 20.7794 21 20.3978 21 20V5C21 4.60218 20.842 4.22064 20.5607 3.93934C20.2794 3.65804 19.8978 3.5 19.5 3.5ZM15.5306 14.9694C15.6003 15.0391 15.6556 15.1218 15.6933 15.2128C15.731 15.3039 15.7504 15.4015 15.7504 15.5C15.7504 15.5985 15.731 15.6961 15.6933 15.7872C15.6556 15.8782 15.6003 15.9609 15.5306 16.0306C15.4609 16.1003 15.3782 16.1556 15.2872 16.1933C15.1961 16.231 15.0985 16.2504 15 16.2504C14.9015 16.2504 14.8039 16.231 14.7128 16.1933C14.6218 16.1556 14.5391 16.1003 14.4694 16.0306L12 13.5603L9.53063 16.0306C9.46094 16.1003 9.37822 16.1556 9.28717 16.1933C9.19613 16.231 9.09855 16.2504 9 16.2504C8.90145 16.2504 8.80387 16.231 8.71283 16.1933C8.62178 16.1556 8.53906 16.1003 8.46937 16.0306C8.39969 15.9609 8.34442 15.8782 8.3067 15.7872C8.26899 15.6961 8.24958 15.5985 8.24958 15.5C8.24958 15.4015 8.26899 15.3039 8.3067 15.2128C8.34442 15.1218 8.39969 15.0391 8.46937 14.9694L10.9397 12.5L8.46937 10.0306C8.32864 9.88989 8.24958 9.69902 8.24958 9.5C8.24958 9.30098 8.32864 9.11011 8.46937 8.96937C8.61011 8.82864 8.80098 8.74958 9 8.74958C9.19902 8.74958 9.38989 8.82864 9.53063 8.96937L12 11.4397L14.4694 8.96937C14.5391 8.89969 14.6218 8.84442 14.7128 8.8067C14.8039 8.76899 14.9015 8.74958 15 8.74958C15.0985 8.74958 15.1961 8.76899 15.2872 8.8067C15.3782 8.84442 15.4609 8.89969 15.5306 8.96937C15.6003 9.03906 15.6556 9.12178 15.6933 9.21283C15.731 9.30387 15.7504 9.40145 15.7504 9.5C15.7504 9.59855 15.731 9.69613 15.6933 9.78717C15.6556 9.87822 15.6003 9.96094 15.5306 10.0306L13.0603 12.5L15.5306 14.9694Z'
        fill='currentcolor'
      />
    </svg>
  );
};

export default PikIconCloseSquareFilled;
