import { FunctionComponent, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
  children: ReactNode;
};

const PikPageTitle: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title} — Cobblestone</title>
      </Helmet>
      {children}
    </>
  );
};

export default PikPageTitle;
