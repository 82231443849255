import { FunctionComponent, ReactNode } from 'react';
import PikPageContentWidth from './types/PikPageContentWidth';

type Props = {
  children: ReactNode;
  contentWidth?: PikPageContentWidth;
};

const PikPage: FunctionComponent<Props> = ({ children, contentWidth = 'regular' }) => {
  let contentWidthClassName = '';
  switch (contentWidth) {
    case 'large':
      contentWidthClassName = 'max-w-[1100px]';
      break;
    case 'regular':
      contentWidthClassName = 'max-w-[752px]';
      break;
    case 'small':
      contentWidthClassName = 'max-w-[560px]';
      break;
  }

  return <div className={`w-full ${contentWidthClassName} flex flex-col items-center`}>{children}</div>;
};

export default PikPage;
