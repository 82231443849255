import PikTextColor from '../types/PikTextColor';

const getTextStylesForColor = (color: PikTextColor): string => {
  switch (color) {
    case 'slate-I':
      return 'text-pik-slate-I';
    case 'neutral-500':
      return 'text-pik-neutral-500';
    case 'terracotta':
      return 'text-pik-terracotta';
    case 'white':
      return 'text-pik-white';
  }
};

export default getTextStylesForColor;
