import { UserProfile, AdminProfile } from '../../types/UserProfile';
import UserContextType from './UserContextType';
import { useUserContext } from './UserProvider';

type UserContextInitializedBase = {
  status: 'success';
  refetch: () => void;
};

type UserContextInitialized = UserContextInitializedBase & {
  data: UserProfile;
};

type AdminUserContextInitialized = UserContextInitializedBase & {
  data: AdminProfile;
};

const useAdminUserContext = (): AdminUserContextInitialized => {
  const userContext = useUserContext();

  if (!isInit(userContext)) {
    throw new Error('UserContext has not been not initalized.');
  }

  if (!isAdminUserContext(userContext)) {
    throw new Error('UserContext is not admin.');
  }

  return userContext;
};

export const isInit = (userContext: UserContextType): userContext is UserContextInitialized => {
  return userContext.data !== undefined;
};

export const isAdminUserContext = (
  userContext: UserContextInitialized
): userContext is AdminUserContextInitialized => {
  return userContext.data.has_admin_view && userContext.data.management_company !== null;
};

export default useAdminUserContext;
