import axios from 'axios';
import { getConfig } from './env';

export const getApiURL = (): string => {
  const config = getConfig();

  return config.EXTERNAL_API_ENDPOINT || 'http://localhost:8000';
};

export const unauthedApi = axios.create({
  baseURL: getApiURL(),
});
