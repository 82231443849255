import PikLoadingScreen from '../base_components/pik/PikSpinner/PikLoadingScreen';
import { useUserContext } from '../providers/UserContext/UserProvider';

type EnforceInitializedUserContextProps = {
  children: React.ReactNode;
};

const EnforceInitializedUserContext = ({ children }: EnforceInitializedUserContextProps) => {
  const { status: userProfileStatus, data: userProfileData } = useUserContext();

  if (userProfileStatus !== 'success' || userProfileData === undefined) {
    return <PikLoadingScreen />;
  }

  return <>{children}</>;
};

export default EnforceInitializedUserContext;
