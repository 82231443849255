import { FunctionComponent } from 'react';

const PikIconXRound: FunctionComponent = () => {
  return (
    <svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2.75C10.0716 2.75 8.18657 3.32183 6.58319 4.39317C4.97982 5.46451 3.73013 6.98726 2.99218 8.76884C2.25422 10.5504 2.06114 12.5108 2.43735 14.4021C2.81355 16.2934 3.74215 18.0307 5.10571 19.3943C6.46928 20.7579 8.20656 21.6865 10.0979 22.0627C11.9892 22.4389 13.9496 22.2458 15.7312 21.5078C17.5127 20.7699 19.0355 19.5202 20.1068 17.9168C21.1782 16.3134 21.75 14.4284 21.75 12.5C21.7473 9.91498 20.7192 7.43661 18.8913 5.60872C17.0634 3.78084 14.585 2.75273 12 2.75ZM15.5306 14.9694C15.6003 15.0391 15.6556 15.1218 15.6933 15.2128C15.731 15.3039 15.7504 15.4015 15.7504 15.5C15.7504 15.5985 15.731 15.6961 15.6933 15.7872C15.6556 15.8782 15.6003 15.9609 15.5306 16.0306C15.4609 16.1003 15.3782 16.1556 15.2872 16.1933C15.1961 16.231 15.0986 16.2504 15 16.2504C14.9015 16.2504 14.8039 16.231 14.7128 16.1933C14.6218 16.1556 14.5391 16.1003 14.4694 16.0306L12 13.5603L9.53063 16.0306C9.46095 16.1003 9.37822 16.1556 9.28718 16.1933C9.19613 16.231 9.09855 16.2504 9 16.2504C8.90146 16.2504 8.80388 16.231 8.71283 16.1933C8.62179 16.1556 8.53906 16.1003 8.46938 16.0306C8.3997 15.9609 8.34442 15.8782 8.30671 15.7872C8.269 15.6961 8.24959 15.5985 8.24959 15.5C8.24959 15.4015 8.269 15.3039 8.30671 15.2128C8.34442 15.1218 8.3997 15.0391 8.46938 14.9694L10.9397 12.5L8.46938 10.0306C8.32865 9.88989 8.24959 9.69902 8.24959 9.5C8.24959 9.30098 8.32865 9.11011 8.46938 8.96937C8.61011 8.82864 8.80098 8.74958 9 8.74958C9.19903 8.74958 9.3899 8.82864 9.53063 8.96937L12 11.4397L14.4694 8.96937C14.5391 8.89969 14.6218 8.84442 14.7128 8.8067C14.8039 8.76899 14.9015 8.74958 15 8.74958C15.0986 8.74958 15.1961 8.76899 15.2872 8.8067C15.3782 8.84442 15.4609 8.89969 15.5306 8.96937C15.6003 9.03906 15.6556 9.12178 15.6933 9.21283C15.731 9.30387 15.7504 9.40145 15.7504 9.5C15.7504 9.59855 15.731 9.69613 15.6933 9.78717C15.6556 9.87822 15.6003 9.96094 15.5306 10.0306L13.0603 12.5L15.5306 14.9694Z'
        fill='currentcolor'
      />
    </svg>
  );
};

export default PikIconXRound;
