import { authedApi } from './auth';
import { UserProfile } from '../types/UserProfile';

const getUserProfile = async (): Promise<UserProfile> => {
  const response = await authedApi.get('/identity/profile');

  return response.data;
};

export default getUserProfile;
