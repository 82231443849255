import { createSlice } from '@reduxjs/toolkit';

interface GlobalState {
  addCosigner: AddCosignerState;
}

interface AddCosignerState {
  email: string;
}

const addCosignerSlice = createSlice({
  name: 'addCosigner',
  initialState: { email: '' },
  reducers: {
    setCosignerEmail(state, action) {
      state.email = action.payload;
    },
  },
});

export const selectCosignerEmail = (state: GlobalState): string => {
  return state.addCosigner.email;
};

export const { setCosignerEmail } = addCosignerSlice.actions;
export default addCosignerSlice.reducer;
