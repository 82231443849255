import { authedApi } from '../../../../api/auth';
import FileTypesForDocumentsSchema from '../types/FileTypesForDocumentsSchema';

const getFileTypeMapping = async (): Promise<FileTypesForDocumentsSchema> => {
  const resp = await authedApi.get('/files/file-types');

  return resp.data;
};

export default getFileTypeMapping;
