// Styles are correlated with pixels
type TextSize = '12' | '13' | '14' | '16' | '18';

const getTextStylesForSize = (textSize: TextSize) => {
  switch (textSize) {
    case '12':
      return 'text-[12px] leading-[140%]';
    case '13':
      return 'text-[13px] leading-[140%]';
    case '14':
      return 'text-[14px] leading-[150%] tracking-[-0.07px]';
    case '16':
      return 'text-[16px] leading-[150%] tracking-[-0.16px]';
    case '18':
      return 'text-[18px] leading-[150%] tracking-[-0.27px]';
  }
};

export default getTextStylesForSize;
