import { AxiosError, AxiosResponse } from 'axios';
import ErrorDisplayType from './types/ErrorDisplayType';

interface CobblestoneErrorData<T = unknown> {
  error_code: string;
  message: string;
  notes: string | null;
  preferred_display_type: ErrorDisplayType;
  extra: T;
}

export type CobblestoneErrorResponse<T = unknown> = AxiosResponse<CobblestoneErrorData<T>>;

export type CobblestoneError<T = unknown> = AxiosError<CobblestoneErrorData<T>> & {
  response: AxiosResponse<CobblestoneErrorData<T>>;
};

export const isCobblestoneError = (error: unknown): error is CobblestoneError => {
  return (
    error instanceof AxiosError &&
    error.response !== undefined &&
    typeof error.response.data === 'object' &&
    'error_code' in error.response.data &&
    'message' in error.response.data &&
    'preferred_display_type' in error.response.data
  );
};

export const isCobblestoneErrorWithCodes = (
  error: unknown,
  codesToCheck: string[]
): error is CobblestoneError => {
  return isCobblestoneError(error) && codesToCheck.includes(error.response.data.error_code);
};

export const isCobblestoneErrorWithCode = (
  error: unknown,
  codeToCheck: string
): error is CobblestoneError => {
  return isCobblestoneErrorWithCodes(error, [codeToCheck]);
};
