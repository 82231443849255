import toast, { Toaster } from 'react-hot-toast';
import { classNames } from '../../utils/classNames';
import PikIconCheckCircleFilled from '../pik/PikIcon/icons/CheckCircleFilled/PikIconCheckCircleFilled';
import getTextStylesForSize from '../pik/PikText/utils/getTextStylesForSize';
import PikIconCloseSquareFilled from '../pik/PikIcon/icons/CloseSquareFilled/PikIconCloseSquareFilled';
import { ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import PikSpinner from '../pik/PikSpinner/PikSpinner';
import PikIconReplace from '../pik/PikIcon/icons/Replace/PikIconReplace';
import PikToastVariant from './PikToastVariant';

export const PikToaster = () => {
  // TODO: we're going to want to pass left space as a prop since it'll be positioned differently
  // for admin and applicant view
  return (
    <Toaster
      containerStyle={{
        inset: undefined,
      }}
      // Note: The lg:left value should match the sidebar's width set in tailwind.config.js
      containerClassName='left-[16px] lg:left-[220px] top-[16px] right-[72px] bottom-[32px]'
    />
  );
};

type PikToastArgs = {
  variant: PikToastVariant;
  title: string;
  body?: string;
  id?: string; // Used to replace an existing toast
};

export const triggerPikToast = ({ variant, title, body, id }: PikToastArgs) => {
  let variantIcon: ReactNode | undefined;
  let duration;
  switch (variant) {
    case 'success':
      variantIcon = (
        <div className='w-[22px] h-[22px] text-pik-earth'>
          <PikIconCheckCircleFilled />
        </div>
      );
      duration = 4000;
      break;
    case 'error':
      variantIcon = (
        <div className='w-[22px] h-[22px] text-pik-terracotta-light'>
          <PikIconCloseSquareFilled />
        </div>
      );
      duration = 4000;
      break;
    case 'replace':
      variantIcon = (
        <div className='w-[22px] h-[22px] text-pik-white'>
          <PikIconReplace />
        </div>
      );
      duration = 6000;
      break;
    case 'loading':
      variantIcon = (
        <div className='w-[22px] h-[22px]'>
          <PikSpinner variant='light' size='small' />
        </div>
      );
      duration = Infinity;
      break;
  }

  return toast.custom(
    (t) => {
      const baseToastClassNames =
        'bg-pik-slate-I rounded-[2px] p-[12px_32px_12px_12px] flex flex-row items-stretch max-w-[400px] pik-toast-shadow';
      return (
        <Transition
          appear
          show={t.visible}
          className='relative toast-enter-from'
          enter='duration-[250ms]'
          enterFrom='toast-enter-from'
          enterTo='toast-enter-to'
          leave='duration-[250ms]'
          leaveFrom='toast-enter-to'
          leaveTo='toast-enter-from'
        >
          <div className={classNames(baseToastClassNames)}>
            <div className='flex flex-col items-start mr-[12px]'>{variantIcon}</div>
            <div>
              <p
                className={`${getTextStylesForSize('14')} font-[500] text-pik-white`}
                style={{ overflowWrap: 'anywhere' }}
              >
                {title}
              </p>
              {body !== undefined && (
                <p
                  className={`${getTextStylesForSize('12')} text-pik-neutral-300`}
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {body}
                </p>
              )}
            </div>
          </div>
        </Transition>
      );
    },
    {
      id,
      duration,
      position: 'bottom-right',
    }
  );
};
