import { FunctionComponent } from 'react';
import PikHeading from '../PikText/PikHeading';
import PikDivider from '../PikDivider/PikDivider';
import PikText from '../PikText/PikText';

type Props = {
  title: string;
  subtitle?: string;
};

const PikCardTitle: FunctionComponent<Props> = ({ title, subtitle }) => {
  return (
    <div className='w-full text-center'>
      <PikHeading size='3' serif>
        {title}
      </PikHeading>
      {subtitle !== undefined && (
        <div className='w-full whitespace-pre-wrap'>
          <PikDivider size='xs' />
          <PikText size='s' color='neutral-500' inline>
            {subtitle}
          </PikText>
        </div>
      )}
    </div>
  );
};

export default PikCardTitle;
