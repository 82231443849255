import { useQuery } from '@tanstack/react-query';
import getFileTypeMapping from '../../base_components/pik/PikUpload/api/getFileTypeMapping';

// Queries here are designed to be queried in the background, so that there's likely cached
// data by the time they're actually used.
const useBackgroundQueries = () => {
  const { data: _ } = useQuery(
    ['file-type-mapping'],
    () => {
      return getFileTypeMapping();
    },
    {
      staleTime: 60 * 60 * 24 * 1000, // 24 hours
    }
  );
};

export default useBackgroundQueries;
