import { authedApi } from '../../../api/auth';
import InviteDetailSchema from '../types/InviteDetailSchema';

const getInvite = async (inviteId: string): Promise<InviteDetailSchema> => {
  const response = await authedApi.get(`/management-company/invites/${inviteId}`);

  return response.data;
};

export default getInvite;
