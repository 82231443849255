import { FunctionComponent } from 'react';
import UserProvider from '../providers/UserContext/UserProvider';
import EnforceInitializedUserContext from '../components/EnforceInitializedUserContext';
import { Route, Routes } from 'react-router-dom';
import InviteScreen from '../features-admin/management-company/components/InviteScreen';
import InviteSetupScreen from '../features-admin/management-company/components/InviteSetupScreen';
import ThrowRouteError from '../features/error_handling/components/ThrowRouteError';

const AdminInviteRoutes: FunctionComponent = () => {
  return (
    <UserProvider>
      <EnforceInitializedUserContext>
        <Routes>
          <Route index element={<InviteScreen />} />
          <Route path='setup' element={<InviteSetupScreen />} />
          <Route path='*' element={<ThrowRouteError />} />
        </Routes>
      </EnforceInitializedUserContext>
    </UserProvider>
  );
};

export default AdminInviteRoutes;
