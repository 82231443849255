import { useParams } from 'react-router-dom';

// Should only be used in paths that definitely have an `inviteId` path parameter.
const useUrlInviteId = () => {
  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const inviteId = params.inviteId!;

  return inviteId;
};

export default useUrlInviteId;
