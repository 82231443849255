import { FunctionComponent, useState } from 'react';
import PikValidatedInput from '../../../base_components/pik/PikInput/PikValidatedInput';
import wordHasSymbolsAndNumbers from '../../../utils/hasSymbolsAndNumbers';
import PikButton from '../../../base_components/pik/PikButton/PikButton';
import PikDivider from '../../../base_components/pik/PikDivider/PikDivider';

const passwordIsLongEnough = (newPassword: string) => {
  return newPassword.length >= 8;
};

const validatePasswordMatch = (password: string, passwordConfirmation: string) => {
  return password === passwordConfirmation;
};

const canSubmit = (newPassword: string, confirmNewPassword: string) => {
  return (
    passwordIsLongEnough(newPassword) &&
    wordHasSymbolsAndNumbers(newPassword) &&
    confirmNewPassword.length > 0 // we let a user submit as long as they have typed something,
    // then we validate the passwords match on click rather than disabling the submit button
  );
};

type Props = {
  newPassword: string;
  setNewPassword: (value: string) => void;
  confirmNewPassword: string;
  setConfirmNewPassword: (value: string) => void;
  onClick: () => void;
  isLoading: boolean;
};

const SetPassword: FunctionComponent<Props> = ({
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  onClick,
  isLoading,
}) => {
  const [passwordNotLongEnoughError, setPasswordNotLongEnoughError] = useState<boolean>(false);
  const [passwordsDontMatchError, setPasswordsDontMatchError] = useState<boolean>(false);
  const [passwordHasSymbolsNumbersError, setPasswordHasSymbolsNumbersError] = useState<boolean>(false);

  const onClickWithValidation = () => {
    if (!validatePasswordMatch(newPassword, confirmNewPassword)) {
      setPasswordsDontMatchError(!validatePasswordMatch(newPassword, confirmNewPassword));
    } else {
      onClick();
    }
  };

  const newPasswordHasError = passwordNotLongEnoughError || passwordHasSymbolsNumbersError;
  let newPasswordError = undefined;
  if (passwordNotLongEnoughError) {
    newPasswordError = 'Password must be at least 8 characters';
  } else if (passwordHasSymbolsNumbersError) {
    newPasswordError = 'Password must contain a symbol or number.';
  }

  const confirmPasswordHasError = passwordsDontMatchError;
  let confirmPasswordError = undefined;
  if (passwordsDontMatchError) {
    confirmPasswordError = "These two passwords don't match";
  }

  return (
    <>
      <PikValidatedInput
        size='large'
        label='Password'
        showLabel={true}
        type='password'
        value={newPassword}
        onChange={(value) => {
          // we should clear the errors when the user tries typing again
          setPasswordNotLongEnoughError(false);
          setPasswordHasSymbolsNumbersError(false);
          setNewPassword(value);
        }}
        errorMessage={newPasswordError}
        isError={(_value) => newPasswordHasError}
        placeholder='New Password'
        forceError={newPasswordHasError}
        disabled={false}
        onBlur={() => {
          // we should only show the error when the user clicks off the input
          setPasswordNotLongEnoughError(!passwordIsLongEnough(newPassword));
          setPasswordHasSymbolsNumbersError(!wordHasSymbolsAndNumbers(newPassword));
        }}
      />
      <PikValidatedInput
        size='large'
        label='Confirm Password'
        showLabel={true}
        type='password'
        value={confirmNewPassword}
        onChange={(value) => {
          setConfirmNewPassword(value);
          setPasswordsDontMatchError(false);
        }}
        errorMessage={confirmPasswordError}
        isError={(_value) => confirmPasswordHasError}
        forceError={confirmPasswordHasError}
        placeholder='Confirm Password'
        disabled={false}
        onEnter={onClickWithValidation}
      />
      <PikDivider size='xs' />
      <PikButton
        size='large'
        variant='purple'
        disabled={!canSubmit(newPassword, confirmNewPassword)}
        onClick={onClickWithValidation}
        loading={isLoading}
        fullWidth
      >
        Submit
      </PikButton>
    </>
  );
};

export default SetPassword;
